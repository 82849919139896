.reveal-card {
  width: 100%;
  max-width: var(--max-width-componente);
  border-radius: 12px;
  text-align: justify;
  padding: 20px;
  box-sizing: border-box;
  margin: 20px auto;
  background: #ffffff;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.reveal-card-front,
.reveal-card-back,
.reveal-card-comment {
  background: #f4f6f8;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.reveal-button {
  display: block;
  width: 50%;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  background: #f4f5f7;
  color: #000000;
  border: 1px solid black;
  border-radius: 8px;
  transition: background 0.3s, transform 0.2s;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.reveal-button:hover {
  background: #afd6b7;
  transform: scale(1.01);
}

.reveal-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #cecece; /* Borda apenas na parte superior */
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding-top: 10px;
}


.accordion {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  font-size: 16px;
  color: #007bff;
  transition: color 0.3s;
  padding: 10px;
}

.accordion:hover {
  color: #0056b3;
}

.accordion span {
  font-weight: bold;
}

.accordion svg {
  transition: transform 0.3s;
}

@media (max-width: 768px) {
  .reveal-card {
    width: 100%;
    max-width: var(--max-width-componente);
    border-radius: 12px;
    text-align: left;
    padding: 5px;
    box-sizing: border-box;
    margin: 5px auto;
    background: #ffffff;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

  .reveal-card-front,
  .reveal-card-back,
  .reveal-card-comment {
    background: #f4f6f8;
    color: #333;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.05);
  }

}