.menu-pastas-container {
  height: 90%;  
  display: block;
 /*overflow-y: auto;*/
}

.menu-pastas-header {
  display: flex;
  flex-direction: column;
  justify-content: left !important;
  text-align: left !important;
  margin-top: 10px;
  align-items: left !important;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.menu-pastas-add-folder-container {
  display: flex;
  align-items: left;
  padding: 10px;
}

.menu-toggle-oculto {
  display: none;
}

.menu-pastas-add-folder-label {
  margin-left: 10px;
}

.menu-pastas-item-pasta {
  padding-left: 20px;
  color: blue;
}

.menu-pastas-item-pagina {
  padding-left: 40px;
  color: blue;
}

.menu-pastas-context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  padding: 5px;
}

.menu-pastas-context-menu button {
  display: block;
  width: 100%;
  padding: 5px 10px;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
}

.menu-pastas-context-menu button:hover {
  background-color: #eee;
}

/* Estilos para modal */
.menu-pastas-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.menu-pastas-modal input {
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
}

.menu-pastas-modal button {
  padding: 8px 12px;
  margin-right: 10px;
  cursor: pointer;
}

/* Estilos para visibilidade do menu */
.menu-pastas-visible {
  display: block;
}

.menu-pastas-hidden {
  display: none;
}
