.decision-tree-comic-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.decision-tree-comic-container .frame {
    margin: 20px;
}

.decision-tree-comic-container .frame img {
    max-width: 100%;
    height: auto;
}

.decision-tree-comic-container .frame .no-image {
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px dashed #ccc;
    border-radius: 10px;
    color: #666;
}

.decision-tree-comic-container .decisions {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.decision-tree-comic-container .decisions button {
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.decision-tree-comic-container .decisions button:hover {
    background-color: #0056b3;
}

.decision-tree-comic-container .feedback {
    margin-top: 10px;
    font-weight: bold;
}
