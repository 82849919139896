/* Estilo geral */
.home-logado-container {
    padding: 2rem;
    background: linear-gradient(135deg, #fffeea, #f2fdff);
    color: #333;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Cabeçalho */
  .home-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .home-title {
    font-size: 2rem !important;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #6200ea;
  }
  
  .home-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #7b1fa2;
  }
  
  .home-subscribe-button {
    background-color: #6200ea;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    text-transform: none;
    transition: background-color 0.3s;
  }
  
  .home-subscribe-button:hover {
    background-color: #7b1fa2;
  }
  
  /* Seções */
  .home-section {
    margin-top: 2rem;
    padding: 1.5rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .home-advantages-list {
    list-style-type: disc;
    margin: 1rem auto;
    padding-left: 1.5rem;
    color: #6200ea;
  }
  
  .faq-container {
    margin-top: 1rem;
  }
  
  .MuiAccordion-root {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .MuiAccordionSummary-root {
    background: #f3e5f5;
    padding: 0.5rem 1rem;
    border-radius: 8px;
  }
  
  .MuiAccordionSummary-root:hover {
    background: #e1bee7;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .home-title {
      font-size: 2.5rem;
    }
  
    .home-subtitle {
      font-size: 1.2rem;
    }
  
    .home-advantages-list {
      padding-left: 1rem;
    }
  
    .home-section {
      padding: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .home-title {
      font-size: 2rem;
    }
  
    .home-subtitle {
      font-size: 1rem;
    }
  
    .home-subscribe-button {
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
    }
  
    .home-section {
      padding: 0.75rem;
    }
  }
  