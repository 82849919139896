/* PreencherQuadro.css */
.preencher-quadro-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: 95%;
}

.preencher-quadro-container h2 {
  text-align: center;
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
}

.preencher-quadro-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.preencher-quadro-table th,
.preencher-quadro-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  vertical-align: top;
}

.preencher-quadro-table th {
  background-color: #f2f2f2;
  font-weight: 500;
}

.preencher-quadro-process-wrapper {
  width: 100%;
  min-height: 40px;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  resize: none;
  box-sizing: border-box;
  height: 100%; /* Ensure the div takes the full height of the td */
}

.preencher-quadro-actions {
  text-align: center;
  margin-top: 20px;
}

.preencher-quadro-actions button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.preencher-quadro-actions button:hover {
  background-color: #007bff;
  color: white;
}

.preencher-quadro-button-evaluate {
  background-color: #28a745;
  color: white;
}

.preencher-quadro-button-show-answers {
  background-color: #17a2b8;
  color: white;
}

.preencher-quadro-button-clear-fields {
  background-color: #ffc107;
  color: white;
}

.preencher-quadro-button-clear-incorrects {
  background-color: #dc3545;
  color: white;
}

.preencher-quadro-correct {
  border-color: green !important;
}

.preencher-quadro-incorrect {
  border-color: red !important;
}

.preencher-quadro-partial {
  border-color: rgb(147, 147, 0) !important;
}

.preencher-quadro-no-process {
  background-color: rgb(132, 132, 132) !important;
}

@media (max-width: 768px) {
  .preencher-quadro-container {
    margin: 0;
    padding: 0;
  }

  .preencher-quadro-actions {
    text-align: center;
    margin-top: 20px;
  }

  .preencher-quadro-actions span {
    display: none;
  }
  
}