.estudo-personalizado {
  display: flex;
  height: 100vh; /* Altura total da viewport */
}

.tab-switcher {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  background-color: #464646;
  padding: 10px 0;
  min-width: 60px; /* Largura mínima para os ícones */
  position: fixed;
  top: 0;
  bottom: 0;
}

.tab-switcher.hidden {
  transform: translateX(-400px);
}

.estudo-personalizado-ocultar-mostrar-menu {
  margin: 0;
  padding: 0;
  position: fixed;
  top: 10px;
  left: 60px; /* Espaço para o tab-switcher fixo */
  z-index: 1000;
}

.teste {
  margin: 0;
  padding: 0;
}

.tab-button {
  background: none;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.tab-button.active {
  background-color: #3a3a3a;
  border-left: solid 3px rgb(178, 178, 178);
}

.tab-button .icones-secoes {
  font-size: 24px;
}

.tab-button .tooltip {
  display: none;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
}

.tab-button:hover .tooltip {
  display: block;
}

.tab-content {
  padding: 0px;
  width: 100%;
  background-color: #f9f9f9;
  display: flex;
  flex-grow: 1;
  margin-left: 60px; /* Espaço para o tab-switcher fixo */
}

.conteudo-notas, .conteudo-revisao {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.menu-pastas-container {
  height: 100vh;
  overflow-y: auto;
  margin-top: 50px;
  width: 300px;
  background-color: #f5f5f5;
  border-right: 1px solid #ddd;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 60px; /* Espaço para o tab-switcher */
  transition: transform 0.3s ease;
}

.menu-pastas-container.hidden {
  transform: translateX(-400px);
}

.menu-pastas-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  justify-content: space-between;
}

.menu-pastas-add-folder-label {
  margin-left: 10px;
}

.pagina-conteudo {
  flex-grow: 1;
  margin-left: 300px; /* Espaço para o menu fixo */
  padding: 20px;
  height: 100vh;
  overflow-y: auto;
  transition: margin-left 0.3s ease;
}

.pagina-conteudo.full-width {
  margin-left: 10px; /* Ajuste conforme a largura do tab-switcher */
}

.menu-toggle {
  position: fixed;
  z-index: 1000;
  transition: left 0.3s ease;
 
}

.botao-menu-toggle {
  color: white;
}

.botao-menu-toggle.hidden {
  background-color: white;
  color: #3e3e3e;
}

.menu-toggle.hidden {
  left: 0px;
}

.menu-toggle.oculto {
  display: none !important;
}

/* Ajustes para tablets */
@media (max-width: 800px) {
  .tab-content {
    margin-left: 0;
  }

  .pagina-conteudo {
    margin-left: 0;
  }

  .menu-pastas-container {
    position: fixed;
    left: 0;
    transform: translateX(0%);
    width: 100%;
    z-index: 1000; /* Garante que o menu esteja acima de outros elementos */
    transition: transform 0.3s ease; /* Transição suave ao mostrar/esconder */
    height: 85% !important; /* O menu ocupa a altura total da tela */
    background-color: white; /* Para destacar o menu no fundo */
  }

  .menu-pastas-container.show {
    transform: translateX(0);
  }


  .menu-pastas-container.hidden {
    transform: translateX(-1025px);
  }

  .tab-switcher {
    position: fixed;
    left: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }

  .tab-button {
    flex-grow: 1;
    justify-content: center;
  }

  .tooltip {
    display: none;
  }
}

/* Ajustes para smartphones */
@media (max-width: 768px) {
  .tab-switcher {
    padding: 5px 0;
    width: 100%; /* O tab-switcher ocupa a largura total na parte inferior */
    bottom: 0; /* Posiciona no fundo da tela */
    margin: 0px;
  }

  .tab-button .icones-secoes {
    font-size: 18px;
  }

  .pagina-conteudo {
    padding: 10px;
    margin-left: 0; /* Remove o espaço reservado para o menu, já que ele será ocultável */
  }

  .menu-pastas-container {
    position: fixed;
    left: 0;
    top: 0; /* Posiciona no topo da tela */
    width: 100%; /* O menu ocupa a largura total da tela */
    height: 90% !important; /* O menu ocupa a altura total da tela */
    background-color: white; /* Para destacar o menu no fundo */
    transform: translateX(0%); /* Esconde o menu fora da tela à esquerda */
    z-index: 1000; /* Garante que o menu esteja acima de outros elementos */
    transition: transform 0.3s ease; /* Transição suave ao mostrar/esconder */
  }

  .menu-pastas-container.show {
    transform: translateX(0); /* Mostra o menu */
  }

  .menu-pastas-container.hidden {
    transform: translateX(-1024px);
  }

  .menu-toggle {
    top: auto;
    bottom: 10px;
    left: 10px;
    z-index: 1001; /* Garante que o botão de toggle esteja acima do menu */
  }

  .tooltip {
    display: none !important;
  }

  .tab-switcher {
    z-index: 1001;
  }

  .pagina-conteudo.full-width {
    margin-left: 0px; 
    padding: 4px;
  }

}