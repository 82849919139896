@font-face {
  font-family: 'Virgil';
  src: url('./fonts/Virgil.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cascadia';
  src: url('./fonts/Cascadia.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.app-container {
  display: flex;
  overflow: hidden;
  font-family: "Inter", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: white; /* Mantemos o texto branco para contraste */
  margin: 0.5em 0;
  padding: 0.5em;
  line-height: 1.2;
  border-radius: 8px;
  text-align: left;
}

/* Título principal com gradiente */
h1 {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: #fff !important;
  padding: 1rem;
  background: linear-gradient(90deg, #4CAF50, #03A9F4);
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Título secundário com faixa sólida */
h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff !important;
  background-color: #0e9a98;
  padding: 0.5rem 1rem;
  display: inline-block;
  margin-bottom: 1rem;
  border-radius: 5px;
}

/* Títulos de seção com destaque */
h3 {
  font-size: 2rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  color: #333;
  background-color: #fced6d;
  border-left: 5px solid #FF9800;
  margin-bottom: 1rem;
}

/* Títulos menores com barra inferior */
h4 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #333;
  position: relative;
  margin-bottom: 1rem;
}


/* Tons ainda mais suaves para h5 */
h5 {
  background: linear-gradient(135deg, #c000d2 0%, #e033ff 100%);
  font-size: 1.25em;
}

/* Quase branco para h6 */
h6 {
  background: linear-gradient(135deg, #ffd4fc 0%, #ffb6bd 100%);
  font-size: 1em;
  font-weight: 600;
  color: #000;
}


.comentarios-verde {
  background-color: rgb(104, 227, 159);
}

.comentarios-azul {
  width: 100%;
  background-color: rgb(104, 206, 227);
}

.comentarios-amarelo {
  width: 100%;
  background-color: rgb(239, 251, 184);
}

.comentarios-vermelho {
  width: 100%;
  background-color: rgb(253, 171, 171);
}

.comentarios-laranja {
  width: 100%;
  background-color: rgb(255, 192, 119);
}

.comentarios-lilas {
  width: 100%;
  background-color: rgb(227, 171, 253);
}

.comentarios-rosa {
  width: 100%;
  background-color: rgb(255, 133, 214);
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.menu-visible .content {
  margin-left: 250px;
}

.menu-hidden .content {
  margin-left: 0;
}

.content {
  flex: 1;
  overflow-y: hidden;
  padding-top: 60px; /* Espaço para o Header */
}

.menu {
  width: 250px;
  transition: transform 0.3s, width 0.3s;
}

.menu-hidden .menu {
  transform: translateX(-250px);
  width: 0;
}

p {
  font-size: 16pt !important;
}

/* Media query para celulares */
@media (max-width: 600px) {
  h1 {
    font-size: 18pt;
  }

  h2 {
    font-size: 17pt;
  }

  h3 {
    font-size: 16pt;
  }

  h4 {
    font-size: 15pt;
  }

  h5 {
    font-size: 14pt;
  }

  h6 {
    font-size: 13pt;
  }
  p {
    font-size: 13pt !important;
  }

}