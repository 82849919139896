.image-occlusion-container {
  display: inline-block;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-occlusion-image-container {
  position: relative;
  width: 100%;
}

.image-occlusion-full {
  display: block;
  width: 100%;
  height: auto;
}

.image-occlusion-occlusion {
  position: absolute;
  background-color: rgb(255, 247, 221);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  overflow: hidden; /* Garante que o texto não transborde a oclusão */

}

.image-occlusion-input {
  width: 100%;
  height: 100%;
  border: 2px solid black;
  text-align: center;
  background-color: rgba(255, 255, 255);
}

.image-occlusion-button {
  margin: 10px 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.image-occlusion-button:hover {
  background-color: #0056b3;
}

.image-occlusion-button svg {
  margin-right: 8px;
}

.image-occlusion-results {
  margin-top: 10px;
  font-size: 14px;
  color: green;
  text-align: center;
}

.image-occlusion-occlusion.correct {
  background-color: rgb(32, 138, 32);
}

.image-occlusion-occlusion.incorrect {
  background-color: rgba(255, 0, 0);
}

.image-occlusion-correct-answer {
  position: absolute; /* Para sobrepor a oclusão */
  top: 0;
  left: 0;
  width: 100%; /* Preenche toda a largura da oclusão */
  height: 100%; /* Preenche toda a altura da oclusão */
  display: flex; /* Facilita a centralização */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  font-size: 1rem; /* Ajuste conforme necessário */
  font-weight: bold;
  background-color: rgba(243, 250, 141, 0.9); /* Fundo semitransparente (opcional) */
  color: rgb(48, 6, 187); /* Cor do texto */
  z-index: 10; /* Garantir que esteja acima de outros elementos */
  pointer-events: none; /* Impede interação do usuário */
}


.image-occlusion-text {
  color: white;
  white-space: nowrap; /* Evita que o texto quebre linhas */
  overflow: hidden; /* Esconde o texto que não cabe */
  text-overflow: ellipsis; /* Adiciona reticências se o texto for muito longo */
}

.image-occlusion-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.image-occlusion-occlusion {

}

.image-occlusion-correct {
  color: green;
  font-weight: bold;
  display: block;
  text-align: center;
}


@media (max-width: 768px) {
  .image-occlusion-container {
    margin: 0;
    padding: 0;
  }

  /* Media query para telas menores que 768px */
  /* Oculta o texto nos botões */
  .image-occlusion-button {
    justify-content: center; /* Centraliza o ícone no botão */
  }
  .image-occlusion-button::after {
    content: '' !important; /* Remove o texto adicional */
  }
  .image-occlusion-button span {
    display: none !important; /* Oculta o texto que acompanha o ícone */
  }

  .image-occlusion-input-select {
    font-size: 9px !important;
    width: 100% !important;
  }

}