.image-occlusion-por-clique-container {
    display: inline-block;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .image-occlusion-por-clique-image-container {
    position: relative;
    width: 100%;
  }
  
  .image-occlusion-por-clique-full {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .image-occlusion-por-clique-occlusion {
    position: absolute;
    background-color: rgb(124, 141, 249);
    border-style: solid;
    border-width: 3px;
    border-color: black;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .image-occlusion-por-clique-text {
    color: white;
    font-size: 16px;
    text-align: center;
    font-weight: 700;
  }
  
  .image-occlusion-por-clique-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .image-occlusion-por-clique-button {
    margin: 10px 5px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }
  
  .image-occlusion-por-clique-button:hover {
    background-color: #0056b3;
  }
  
  .image-occlusion-por-clique-button svg {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    .image-occlusion-por-clique-container {
      margin: 0;
      padding: 0;
    }
  
    /* Media query para telas menores que 768px */
    /* Oculta o texto nos botões */
    .image-occlusion-por-clique-button {
      justify-content: center; /* Centraliza o ícone no botão */
    }
    .image-occlusion-por-clique-button::after {
      content: '' !important; /* Remove o texto adicional */
    }
    .image-occlusion-por-clique-button span {
      display: none !important; /* Oculta o texto que acompanha o ícone */
    }

    .image-occlusion-por-clique-text {
      font-size: 11px; /* Reduz o tamanho da fonte para dispositivos móveis */
    }
  }
  