.UsuarioPage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.UsuarioPage-edit-mode-toggle {
  position: fixed;
  top: 60px; /* Ajuste conforme necessário */
  right: 20px; /* Posiciona no canto direito */
  display: flex;
  flex-direction: column; /* Alinha os botões verticalmente */
  gap: 5px; /* Espaçamento entre os botões */
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 3px; /* Adiciona padding para que os botões fiquem um pouco distantes das bordas */
  z-index: 1000; /* Garante que os botões fiquem acima de outros conteúdos */
}


.UsuarioPage-droppable {
  width: 100%;
  max-width: 800px;
}

.UsuarioPage-componente {
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.UsuarioPage-edit-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.UsuarioPage-edit-buttons > * {
  padding: 5px;
}

.UsuarioPage-header {
  position: fixed;
  top: 60px;
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

button.add-componente-button {
  margin-bottom: 20px;
}

.UsuarioPage-content {
  padding: 20px;
  width: 98%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.UsuarioPage-componente {
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  width: 90%;
  position: relative;
}

.UsuarioPage-componente-header {
  display: flex;
  justify-content: flex-end; /* Alinha o ícone de tela cheia à direita */
  margin-bottom: 10px;
}

.UsuarioPage-componente-header .MuiIconButton-root {
  padding: 5px;
}

.UsuarioPage-componente img, 
.UsuarioPage-componente iframe, 
.UsuarioPage-componente video {
  max-width: 100%;
  height: auto;
}

.UsuarioPage-componente .MuiPaper-root,
.UsuarioPage-componente .MuiCard-root {
  width: 100%;
}

.MuiSelect-select {
  padding: 10px 30px 10px 10px !important;
}

.UsuarioPage-sequential-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  bottom: 1px;
  padding: 5px 15px 5px 5px;
  position: fixed;
  width: calc(100% - 75px);
  z-index: 1010;
  overflow: hidden;
  background-color: #fff;
  box-sizing: border-box;
  max-width: 100vw;
  right: 15px; 
}

@media (max-width: 768px) {
  .UsuarioPage-content {
    padding: 0px;
    margin-bottom: 110px;
    margin-top: 30px;
    align-items: center;
    overflow-x: hidden;
    top: 50px; 
    width: 100%;
  }

  .UsuarioPage-edit-buttons {
    display: none !important; /* Esconde os botões de editar/visualizar */
  }

  .UsuarioPage-edit-mode-toggle {
    top: 50px; /* Fixa no topo da página */
    right: 0;
    width: 100%; /* O botão de alternar visualização ocupa toda a largura */
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .UsuarioPage-componente {
    margin-top: 10px;
    margin-bottom: 10px !important;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-y: auto; /* Adiciona rolagem vertical se o conteúdo ultrapassar a altura máxima */
    overflow-x: hidden; /* Adiciona rolagem horizontal se o conteúdo ultrapassar a largura máxima */
    box-sizing: border-box; /* Inclui padding e borda no tamanho total da div */
    max-width: 100%; 
    margin-bottom: 50px;
  }

  .UsuarioPage-componente img, 
  .UsuarioPage-componente iframe, 
  .UsuarioPage-componente video {
    max-width: 100%;
    height: auto;
  }

  .UsuarioPage-componente .MuiPaper-root,
  .UsuarioPage-componente .MuiCard-root {
    width: 100%;
  }

  .UsuarioPage-sequential-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    bottom: 50px;
    padding: 5px 15px 5px 5px;
    position: fixed;
    width: 92%;
    z-index: 1010;
    background-color: #fff;
    max-width: 100%;
  }

  .botao-editar-visualizar-componente {
    display: none !important;
  }

}

