.RevisaoEspacadaViewer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
  }

  .RevisaoEspacadaPage-dialogContent {
    padding: 10px;
  }
  
  .RevisaoEspacadaViewer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Centraliza verticalmente o conteúdo */
    align-items: center;      /* Centraliza horizontalmente o conteúdo */
    max-width: 90%;           /* Limita a largura do conteúdo */
    width: 100%;              /* Garante que ele preencha o contêiner */
    flex-grow: 1;
    padding: 10px;            /* Mantém um espaçamento interno */
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;         /* Permite rolagem vertical */
    overflow-x: hidden;       /* Evita rolagem horizontal */
    margin-bottom: 5px;
    margin-bottom: 55px;
}

.RevisaoEspacadaViewer-motion{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;  /* Centraliza verticalmente o conteúdo */
  align-items: center;  
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}




  .RevisaoEspacadaViewer-content img, 
  .RevisaoEspacadaViewer-content iframe, 
  .RevisaoEspacadaViewer-content video {
      max-width: 100%;
      height: auto;
    }
  
    .RevisaoEspacadaViewer-buttons {
      position: fixed;
      bottom: 0;            /* Fixa os botões no fundo da página */
      left: 0;
      width: 100%;          /* Garante que os botões ocupem a largura completa */
      display: flex;
      gap: 10px;
      justify-content: center;
      padding: 10px;
      background-color: #f9f9f9;  /* Adiciona uma cor de fundo para destacar os botões */
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Adiciona uma leve sombra */
      z-index: 1000;        /* Garante que os botões fiquem acima de outros elementos */
  }

  .RevisaoEspacadaViewer-buttons-dentro {
    width: 50%;
    bottom: 0;            /* Fixa os botões no fundo da página */
      left: 0;    
      display: flex;
      gap: 10px;
      justify-content: center;
  }
  
  
  .RevisaoEspacadaViewer-buttons button {
      width: 50%;
      gap: 10px;
      font-size: 16px !important;
      text-transform: none !important;
      padding-top: 3px;
      padding-bottom: 3px;
  }

  
  @media (max-width: 768px) {
    .RevisaoEspacadaViewer-container {

      padding: 5px;

    }
    .RevisaoEspacadaViewer-content {
      padding: 0;
      margin: 0;
      margin-bottom: 75px;
    }
  
    .RevisaoEspacadaViewer-buttons {
      width: 100%;
      gap: 10px;
      font-size: 12px !important;
    }
    .RevisaoEspacadaViewer-buttons-dentro {
      width: 100%;
    }

    .RevisaoEspacadaViewer-buttons button {
      width: 100%;
      gap: 10px;
      font-size: 16px !important;
      text-transform: none !important;
      padding-top: 3px;
      padding-bottom: 3px;
    }

    .RevisaoEspacadaViewer-button {
      display: flex;
      flex-direction: column;
      justify-content:flex-start;
      gap: 0px !important;
    }

    .intervalo-span {
      font-size: 12px;
    }
  }
  