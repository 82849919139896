body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;       /* Elimina a rolagem horizontal */
}

h1, h2, h3 {
  color: #333;
}

a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #0056b3;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
