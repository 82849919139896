/* Estilos gerais da página de cursos */
.cursos-page {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .cursos-title {

    text-align: center;
    width: 98%;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Contêiner flexível para organizar os cartões de curso */
  .cursos-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  /* Estilo de cada cartão de curso */
  .curso-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 150px;
    padding: 0;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .curso-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .CursosPage-container-image {
    width: 100%;
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    justify-content: center;
    background-color: #c0daf7;
  }
  
  /* Estilo da imagem do curso no topo do cartão */
  .curso-card-image {
    width: 80%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
  }
  
  /* Conteúdo interno do cartão */
  .curso-card-content {
    text-align: center;
    padding: 15px;
  }

  .curso-card-content p {
    font-size: 22px !important;
    font-weight: bolder;
  }
  
  .curso-card-content h3 {
    font-size: 1.5rem;
    color: #555;
  }
  
  /* Layout responsivo */
  @media (max-width: 768px) {
    .curso-card-image {
        width: 70%;
      }
  }
  