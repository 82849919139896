.ImageOcclusionEditor-container {
  position: relative;
  margin: 0 auto;
  border: 1px solid #ccc;
  overflow: hidden;
}

.botaoExcluirSelecionada {
  display: none;
}

.botoesImageOcclusion {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ImageOcclusionEditor-image {
  display: block;
}

.ImageOcclusionEditor-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: crosshair;
}

.ImageOcclusionEditor-occlusion {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5);
}

.ImageOcclusionEditor-input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  color: white;
  font-weight: bold;
  text-align: center;
}

.delete-occlusion-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 10;
}

.delete-occlusion-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  color: white;
  background-color: red;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  z-index: 10;
}

.ImageOcclusionEditor-occlusion.selected {
  border: 2px solid red;
}


