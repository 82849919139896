.home-deslogado-container {
    text-align: center;
    padding: 2rem;
  }
  
  .home-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .home-subtitle {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .home-signup-button {
    background-color: #4caf50;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    text-transform: none;
  }
  
  .home-section {
    margin-top: 2rem;
    text-align: left;
  }
  
  .home-advantages-list {
    list-style-type: disc;
    margin: 1rem auto;
    padding: 0 1.5rem;
  }
  