.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Usa min-height para evitar cortes */
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll; /* Garante a rolagem vertical */
  background: linear-gradient(135deg, #6dd5ed, #2193b0); /* Gradiente moderno */
  color: #fff;
  text-align: center;
}

.login-title {
  margin-bottom: 20px;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.tituloLogin {
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
}

.login-form {
  width: 100%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.9); /* Fundo branco translúcido */
  padding: 20px;
  border-radius: 12px; /* Bordas arredondadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para destaque */
  color: #333; /* Cor do texto */
  text-align: left; /* Texto alinhado para legibilidade */
}

.login-button {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  background: #2193b0; /* Azul chamativo */
  color: #fff;
  font-weight: bold;
  padding: 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}

.login-button:hover {
  background: #6dd5ed; /* Efeito hover */
}

.error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .login-container {
    padding: 15px;
  }

  .tituloLogin {
    font-size: 1.5rem;
  }

  .login-form {
    max-width: 100%;
    padding: 15px;
  }

  .login-button {
    font-size: 1rem;
  }
}

/* Responsividade para telas muito pequenas */
@media (max-width: 480px) {
  .login-container {
    padding: 10px;
  }

  .tituloLogin {
    font-size: 1.3rem;
    text-align: center;
    font-weight: 700;
  }

  .login-button {
    font-size: 0.9rem;
    padding: 10px;
  }
}
