.complete-texto-body {
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
  color: #333;
}

.complete-texto-container {
  max-width: var(--max-width-componente);
  width: 100%;
  margin: 50px 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.complete-texto-container h1 {
  text-align: center;
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.complete-texto-container #textContainer {
  margin: 20px 0;
  font-size: 18px;
  line-height: 1.6;
}

.complete-texto-actions {
  text-align: center;
  margin-top: 20px;
}

.complete-texto-button {
  padding: 12px 20px;
  margin: 5px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  margin-top: 20px;
}


.complete-texto-button button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.complete-texto-button button:hover {
  background-color: #007bff;
  color: white;
}

.complete-texto-btn-evaluate {
  background-color: #28a745;
  color: white;
}

.complete-texto-btn-show {
  background-color: #17a2b8;
  color: white;
}

.complete-texto-btn-clear {
  background-color: #bd6f02;
  color: white;
}

.complete-texto-btn-clear-all {
  background-color: #ff0707;
  color: white;
}

.complete-texto-correct {
  color: green;
}

.complete-texto-incorrect {
  color: red;
  text-decoration: line-through;
}

.complete-texto-input.blank {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  font-size: 16px;
  width: 50px; /* Tamanho inicial */
  display: inline-block;
  font-weight: normal;
  /*transition: width 0.2s;  Transição suave */
}

.complete-texto-input.blank.bold {
  font-weight: bold;
}

.complete-texto-input.blank.blue {
  color: blue;
  font-weight: bold;
}

.complete-texto-input.dropdown {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  font-size: 16px;
  width: auto;
  display: inline-block;
  font-weight: normal;
}

.complete-texto-input.dropdown.bold {
  font-weight: bold;
}

.complete-texto-input.dropdown.blue {
  color: blue;
  font-weight: bold;
}

#results {
  text-align: center;
  color: #fff;
  background-color: #3f8444;
  font-weight: bolder;
  padding: 10px;
}

@media (max-width: 768px) {

  .complete-texto-container {
    max-width: var(--max-width-componente);
    width: 100%;
    margin: 0;
    padding: 10px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  #results {
    text-align: center;
    color: #fff;
    background-color: #3f8444;
    font-weight: bolder;
    padding: 10px;
  }

  .complete-texto-button span {
    display: none;
  }

}